"use client"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import * as z from "zod"
import { Button } from "@/components/ui/button"
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormMessage,
} from "@/components/ui/form"
import {
    Dialog,
    DialogClose,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/components/ui/dialog"
import { Input } from "@/components/ui/input"
import { Textarea } from "@/components/ui/textarea"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../ui/select";
import 'react-phone-number-input/style.css'
import PhoneInput from "react-phone-number-input"
import { useEffect, useState } from "react"
import { getIpsAddress } from "@/lib/getIP"
import Image from "next/image"
import { useRef } from "react";
import { Icons } from "../ui/icons";
import { TiTick } from "react-icons/ti";


const ContactForm = (props: any) => {

    const regBtnRef = useRef<any>(null);
    const [value, setValue] = useState<string>('');
    const [loading, setLoading] = useState(false)
    const handlePhoneInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        // Update the value state when the input changes
        setValue(event.target.value);
    };

    const phoneRegex = new RegExp(
        /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/
    );

    const formSchema = z.object({
        username: z
            .string({ required_error: 'Name is required' })
            .min(3, { message: 'Name must be at least 3 characters' })
            .max(30, { message: 'Name cannot contain more than 30 characters' })
            .refine(
                (value) => /^[A-Za-z][A-Za-z\s]*$/.test(value),
                { message: 'Only alphabets allowed' }
            ),
        message: z.string().optional(),
        email: z.string({ required_error: "Email is required" }).min(1, { message: "Email is required" })
            .regex(/^[A-Za-z0-9@.]+$/, { message: "Invalid email" })
            .email("Invalid email"),
        phone: z.string({ required_error: "Phone number required!", })
            .min(13, { message: 'Phone Number should contain 10 digit' })
            .max(13, { message: 'Phone Number should contain 10 digit' })
            .refine((value) => {
                const zeroCount = (value.match(/0/g) || []).length;
                return zeroCount <= 7;
            }, { message: 'Invalid Phone number.', })
            .refine((value) => {
                // Check if any digit repeats 10 times
                const repeatingDigit = /(\d)\1{9}/;
                return !repeatingDigit.test(value);
            }, {
                message: 'Invalid Phone number.',
            }),
        // select: z.string({ required_error: "Please select option", })
    })

    const form = useForm({
        mode: "onChange",
        resolver: zodResolver(formSchema),
    });

    const onSubmit = async (data: any) => {
        setLoading(true)
        const ips = await getIpsAddress()
        let datas = JSON.stringify({
            "name": data?.username,
            "email": data?.email,
            "phone": data?.phone,
            // "source": data?.select,
            "message": data?.message,
            // "company_name": data?.companyname,
            "locationAppliedFrom": ips,
            "userLogged": '',
        });
        const result = await fetch('api/master/contactus', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(datas)
        })
        setLoading(false)
        if (result?.status === 201) {
            regBtnRef?.current.click();
            form.reset({
                username: "",
                email: "",
                message: "",
                phone: "",
                select: ""
            });
        }
    };

    const [isSelectOpen, setIsSelectOpen] = useState(false);
    const selectRef = useRef(null);

    const handleScroll = () => {
        setIsSelectOpen(false);
    };

    useEffect(() => {
        if (isSelectOpen) {
            document.addEventListener('wheel', handleScroll);
        } else {
            document.removeEventListener('wheel', handleScroll);
        }
        return () => {
            document.removeEventListener('wheel', handleScroll);
        };
    }, [isSelectOpen]);
    const inputValidation = (e: any) => {
        const key = e.key;
        const isAlphabetOrSpace = /^[a-zA-Z ]$/.test(key); // Include space in the regex
        // Allow only alphabets, space, and control keys like Backspace, Arrow keys, etc.
        if (!isAlphabetOrSpace && !['Backspace', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Tab'].includes(key)) {
            e.preventDefault();
        }
    }

    return (
        <><div className="relative pb-4 lg:pb-0 md:pb-0">
            <Form {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
                    <FormField
                        control={form.control}
                        name="username"
                        render={({ field }) => (
                            <FormItem>
                                <FormControl>
                                    <Input placeholder="Name *" className="rounded-none h-11" {...field} onChange={field.onChange} maxLength={30}  onKeyDown={(e:any)=>inputValidation(e)}/>
                                </FormControl>
                                <FormMessage className="absolute text-[12px]" />
                            </FormItem>
                        )} />
                    <FormField
                        control={form.control}
                        name="email"
                        render={({ field }) => (
                            <FormItem>
                                <FormControl>
                                    <Input placeholder="Email *" className="rounded-none h-11" {...field} onChange={field.onChange} type="email"  onKeyDown={(e: any) => {
                                        if (e.keyCode === 32) {
                                            e.preventDefault();
                                        }
                                    }}/>
                                </FormControl>
                                <FormMessage className="absolute text-[12px]" />
                            </FormItem>
                        )} />
                    <FormField
                        control={form.control}
                        name="phone"
                        render={({ field }: any) => (
                            <FormItem className="grid grid-cols-1 items-center gap-6">
                                <div className="">
                                    <FormControl>
                                        <PhoneInput
                                            placeholder="Enter phone number *"
                                            defaultValue={field.value}
                                            onClick={setValue}
                                            defaultCountry="IN"
                                            international
                                            limitMaxLength={true}
                                            maxLength="16"
                                            countryCallingCodeEditable={false}
                                            className="border-[#D2D6DE] bg-white rounded-none h-[44px] h-44p border-solid border ps-2 text-[#828282]"
                                            {...field}
                                            onChange={field.onChange}
                                            rules={{ required: true }} />
                                    </FormControl>
                                    <FormMessage className="absolute text-[12px]" />
                                </div>
                            </FormItem>
                        )} />
                    <FormField
                        control={form.control}
                        name="message"
                        render={({ field }) => (
                            <FormItem>
                                <FormControl>
                                    <Textarea placeholder="Message..." className="rounded-none h-11" {...field} onChange={field.onChange} />
                                </FormControl>
                                <FormMessage className="absolute text-[11px] right-0" />
                            </FormItem>
                        )} />
                    {/* <FormField
                        control={form.control}
                        name="select"
                        render={({ field }) => (
                            <FormItem>
                                <FormControl>
                                <div ref={selectRef}>
                                    <Select onValueChange={field.onChange} defaultValue={field.value} open={isSelectOpen} onOpenChange={setIsSelectOpen}>
                                        <SelectTrigger style={{ marginBottom: "0" }} className="h-11 bg-white focus:ring-0 focus-visible:ring-offset-0 focus-visible:ring-0 rounded-none border border-[#D0D0D0] text-sm text-[#000] mb-8">
                                            <SelectValue placeholder="How did you find us?" className="text-sm" />
                                        </SelectTrigger>
                                        <SelectContent>
                                            <SelectItem value="social media">Social Media</SelectItem>
                                            <SelectItem value="website">Website</SelectItem>
                                            <SelectItem value="referral">Referral</SelectItem>
                                        </SelectContent>
                                    </Select>
                                    </div>
                                </FormControl>
                                <FormMessage className="absolute text-[12px]" />
                            </FormItem>
                        )} /> */}
                    <Button className="h-11 rounded-none text-[14px] tracking-[2px] max-w-[230px] mx-auto table w-[100%] bg-[#C98A43] uppercase font-normal" disabled={loading}>
                        {loading && <Icons.spinner className="mr-2 h-4 w-4 animate-spin" />}Submit</Button>
                </form>
            </Form>
        </div>
            <Dialog>
                <DialogTrigger asChild className="py-5">
                    <Button variant="primary" className="hidden" ref={regBtnRef}>Submit</Button>
                </DialogTrigger>
                <DialogContent className="max-w-[600px]">
                    <div className="bg-white p-6 m-5">
                        <div className="flex items-center w-100 justify-center">
                            <div className="mt-3 text-center sm:mt-0 sm:text-left">
                                <div className="mt-2 text-center">
                                    <div className='my-4'>
                                        <Image height={200} width={200} src='https://exalogic-store.s3.us-east-2.amazonaws.com/exc-academy/common/success.webp' alt='Success' className="inline-block max-w-full w-16" />
                                    </div>
                                    <h6 className='text-[22px] font-medium text-[#4B4B4B]'>Successfully Submitted</h6>
                                    <p className="text-[16px] text-[#949495] my-4">
                                        Thank you for reaching out to Harmony Estate! We've received your request and our team will get back to you shortly
                                    </p>
                                    {/* <Link href="/" className="py-4 mt-4 block m-auto px-6 rounded-none text-[14] tracking-[2px] max-w-[230px] w-[100%] text-white bg-[#C98A43] hover:bg-[#000000] uppercase font-normal">Return to Home</Link> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    )
}
export default ContactForm;